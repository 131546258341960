import React, { useState,useRef } from "react"
import FilaGamer from './fila_gamer'
import FilaCreador from './fila_creador'
import ModCreador from './mod_creador'
import Sponsors from "./sponsors_new"
import YouTube from "react-youtube"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/dist/css/splide.min.css';
import ReactPlayer from 'react-player'

function App() {
  const playerRef = useRef(null);
  const [muted,setMuted] = useState(false)
  const [creadores, setCreadores] = useState([
   /* {
      nombre: "Luay", pais: "Perú", img: "luay.png",redes: [
        {
          tipo:"instagram",
          link:"https://www.instagram.com/luayfire16/"
        },{
          tipo:"youtube",
          link:"https://www.youtube.com/c/Luayfire"
        },{
          tipo:"tiktok",
          link:"https://www.tiktok.com/@luay998?lang=es"
        },
      ]
    }, */{
      nombre: "TheDonato", pais: "Venezuela", img: "donato.png",redes: [
        {
          tipo:"instagram",
          link:"https://www.instagram.com/thedonato/"
        },{
          tipo:"youtube",
          link:"https://www.youtube.com/c/thedonato"
        },{
          tipo:"tiktok",
          link:"https://www.tiktok.com/@thedonato?lang=es"
        },
      ]
    },
    {
      nombre: "TheHectorino", pais: "Mexico", img: "hectorino.png",redes: [
        {
          tipo:"instagram",
          link:"https://www.instagram.com/hectorinoyt/"
        },{
          tipo:"youtube",
          link:"https://www.youtube.com/c/TheHectorino"
        },{
          tipo:"tiktok",
          link:"https://www.tiktok.com/@thehectorinoyt?lang=es"
        },
      ]
    },
    /*{
      nombre: "Lucy", pais: "Perù", img: "lucy.png",redes: [
        {
          tipo:"instagram",
          link:"https://www.instagram.com/luci_ff8/"
        },{
          tipo:"youtube",
          link:"https://www.youtube.com/channel/UCmOiP6fUkh6qbyZguETS46g"
        }
      ]
    },*/
    {
      nombre: "Jeanki", pais: "Mexico", img: "jeanky.png",redes: [
        {
          tipo:"instagram",
          link:"https://www.instagram.com/jeankispanki/"
        },{
          tipo:"youtube",
          link:"https://www.youtube.com/channel/UChIvqjDjNZzarx4al31dU9g"
        },{
          tipo:"tiktok",
          link:"https://www.tiktok.com/@jeanki7?lang=es"
        },
      ]
    },
    {
      nombre: "Punisher", pais: "Argentina", img: "punisher.png",redes: [
        {
          tipo:"instagram",
          link:"https://www.instagram.com/punicher_god/"
        },{
          tipo:"youtube",
          link:"https://www.youtube.com/c/Punicher"
        },{
          tipo:"tiktok",
          link:"https://www.tiktok.com/@punicher_god?lang=es"
        },
      ]
    }
  ])

  const [equipos, setEquipos] = useState([
    {
      nombre: "FREE FIRE", sub: "MOBILE", participantes: ["Yecol",
        "Bran",
        "Lauta",
        "Agus97",
        "Mau",
        "Facu",
        "Yuke",
        "Kuzar",
        "Joaco"]
    },
    {
      nombre: "FREE FIRE ", sub: "EMULADOR LATAM",
      participantes: ["Tornado",
        "Haxixe",
        "Toomi",
        "Sonix",
        "Shox"]
    }
  ])

  const [optsYoutube, setOptsYoutube] = useState({
    height: '100%',
    width: '100%',
    frameBorder: 0,
    controls: 1,
    showinfo: 0,
    modestbranding: 1,
    origin: 'https://airnizer.com/naguara_new/',
    host: "https://www.youtube.com",
    forceSSL: 1,
    enablejsapi: 1,
    autoplay: 1,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      modestbranding: 1,
      controls: 1,
      picture_in_picture: 1,
      showinfo: 0,
      origin: 'https://airnizer.com/naguara_new/',
      host: "https://www.airnizer.com/naguara_new/",
      forceSSL: 1,
      enablejsapi: 1,
    },
  })

  const [optsSplide, setSplide] = useState({
    type: "loop",
    focus: "center",
    start: 1,
    pagination: false,
    arrows: true,
    perPage: 3,
    breakpoints: {
      470: {
        perPage: 1,
      },
    }
  })


  const generaEquipo = (_equipo) => {
    const half = Math.ceil(_equipo.length / 2);
    const firstHalf = _equipo.slice(0, half)
    const secondHalf = _equipo.slice(-half)
    var resp = []
    for (const [i, int] of firstHalf.entries()) {
      resp.push(<div className="row pb-3" key={i}>
        <div className="col-12 col-md-4  ms-md-auto pb-3"> <FilaGamer nombre={int} /> </div>
        <div className="col-12 col-md-4  me-md-auto" > {secondHalf[i] && <FilaGamer nombre={secondHalf[i]} />}</div>
      </div>)
    }
    return resp
  }

  const onReady = (event) => {
    console.log(event.target)
    console.log("READDY")
    //event.target.playVideo();
    //setMuted(false)
   /* event.target.play();
    let _player = document.getElementById("v_1")
    console.log(_player)
    _player.currentTarget.play()*/
  }

  const onPlay = (event) => {
    console.log("PLAY")
    console.log(event)
    //setMuted(false)
  }

  const onError = (event) => {
    console.log("ERROR")
    console.log(event)
  }

  const onEnd = (event) => {
    console.log("END")
    console.log(event)
  }


  return (
    <div className="container-fluid " style={{ paddingRight: "0px", paddingLeft: "0px" }}>
      <div className="row">
        <div className="col-12 col-md  text-center text-md-start ">
          <img src="imgs/logo.svg" className="logo_head_new" />
        </div>
        <div className="col-12 col-md-5 align-self-center">
          <div className="mod_soc_head text-center text-md-end">
            <a className="" href="https://youtube.com/naguaragg" target="_blank"><img src="imgs/sos_youtube.png" className="sos_head" /></a>
            <a className="" href="https://facebook.com/naguaragg" target="_blank"><img src="imgs/sos_facebook.png" className="sos_head" /></a>
            <a className="" href="https://instagram.com/naguara.gg" target="_blank"><img src="imgs/sos_instagram.png" className="sos_head" /></a>
            <a className=" " href="https://twitter.com/naguaragg" target="_blank"><img src="imgs/sos_twitter.png" className="sos_head" /></a>
            <a className="" href="https://booyah.live/naguaragg" target="_blank"><img src="imgs/sos_boyhaa.png" className="sos_head" /></a>
            <a className=" " href="https://tiktok.com/@naguragg" target="_blank"><img src="imgs/sos_tiktok.png" className="sos_head" /></a>
          </div>
          {1===3 &&
          <div className=" text-center text-md-end  d-flex flex-column flex-sm-row">
            <div className="  flex-fill ">
              <a href="#SOMOS" className=" px-3 txt-menu-new w-50">SOMOS NAGUARÁ </a>
            </div>
            <div className="  flex-fill ">
              <a href="#ROSTERS" className=" px-3 txt-menu-new w-50">ROSTERS </a>
            </div>
            <div className="  flex-fill ">
              <a href="#SPONSORS" className=" px-3 txt-menu-new w-50">SPONSORS </a>
            </div>
            <div className="  flex-fill ">
              <a href="#CONTACTO" className=" px-3 txt-menu-new w-50">CONTACTO </a>
            </div>
          </div>
          }
        </div>
      </div>

      <div className="row justify-content-center" id="SOMOS">
        <div className="col-12 col-md-4 align-self-end text-end">
          <h4 className="tit_grande text-end d-none d-md-block" style={{ marginBottom: "-10px" }}>SOMOS</h4>
        </div>
        <div className="col-11 col-md-8">
        <div className="video_container">

                <ReactPlayer ref={playerRef}
            className='react-player'
            url='https://vimeo.com/702922452'
            width='100%'
            height='100%'
            //playerOptions={{autopause: false,autoplay: true,loop: true}}
            controls={true}
            playing={true}
            muted={true}
            loop={true}
            onReady={onReady}                    // defaults -> noop
            onPlay={onPlay}                     // defaults -> noop
            //onPause={func}                    // defaults -> noop
            onEnded={onEnd}                      // defaults -> noop
            onError={onError}  
          />
        </div>

{1==3 &&
          <YouTube
            videoId={"cCFHfwAcsuQ"}                  // defaults -> null
            id={"v_1"}                       // defaults -> null
            className={"video"}                // defaults -> null
            containerClassName={"video_container"}       // defaults -> ''
            title={"NAGUARA"}                    // defaults -> null
            opts={optsYoutube}                        // defaults -> {}
            onReady={onReady}                    // defaults -> noop
            onPlay={onPlay}                     // defaults -> noop
            //onPause={func}                    // defaults -> noop
            //onEnd={func}                      // defaults -> noop
            onError={onError}                    // defaults -> noop
          //onStateChange={func}              // defaults -> noop
          //onPlaybackRateChange={func}       // defaults -> noop
          //onPlaybackQualityChange={func}    // defaults -> noop
          />
}
        </div>
      </div>

      <div className="row justify-content-center pb-4 mt-3" >
        <div className="col-11 col-md-6">
          <h4 className="tit_grande text-start text-md-end d-block d-md-none" style={{ marginBottom: "-10px" }}>SOMOS</h4>
          <h4 className="tit_grande text-start text-md-end" style={{ marginTop: "-15px" }}>NAGUARÁ</h4>
        </div>
        <div className="col-11 col-md-6">
          <p className="txt_somos  blanco">En el año 2019, TheDonato, uno de los creadores de contenido más grandes de Latinoamérica, comenzó la revolución mobile fundando Naguará, la organización de Esports más influyente de la escena del gaming en celulares de la región.
         <br /><br />
Naguará cuenta con tres verticales de desarrollo desde donde dialoga con su público y sus patrocinadores de forma constante: creación de contenido multiplataforma a través de sus influencers, posicionamiento líder en la escena competitiva y organización de eventos y competencias profesionales y amateurs.
             </p>
        </div>
      </div>

      <div >
        <img src="imgs/institucional.png" width="100%" />
      </div>


      <div className="row justify-content-center pb-4" id="ROSTERS">
        <div className="col-11 col-md-4">
          <hgroup className="col-12  mt-5">
            <h4 className="titulo naranja ">CREADORES</h4>
            <h6 className="subtitulo blanco ">DE CONTENIDOS</h6>
          </hgroup>
        </div>
        <div className="col-11 col-md-6 mt-5">
          <p className="txt_creadores  blanco">Detrás de la figura de Donato, en Naguará se encuentran influencers que día a día aportan a la comunidad y al equipo su contenido gamer y colaboran con la organización en la realización y difusión de campañas y eventos o torneos propios.</p>
        </div>
      </div>

{1===4 &
      <div className="creadores">
        <Splide options={optsSplide} aria-label="">
          {creadores.map((creador, i) => {
            return <SplideSlide key={i}>
              <ModCreador creador={creador} />
            </SplideSlide>
          })}
        </Splide>
      </div>
        }


      {1===3 &&
      <div className="row justify-content-center pb-4 misc" >
        <div className="col-11 col-md-11">
          <hgroup className=" mt-5">
            <h4 className="titulo naranja ">ROSTER</h4>
            <h6 className="subtitulo blanco ">FREE FIRE</h6>
          </hgroup>
        </div>
      </div>
}
      <div>
        <img src="imgs/rooster.png" width="100%" />
      </div>
      {1===3 &&
      <div className="row justify-content-center" >
        <img src="imgs/blanco_arriba.png" className="p-0" />
      </div>
}
{1===4 &
      <> <section className="row justify-content-center misc" style={{ backgroundColor: "#fff" }}>

        <hgroup className="col-11 col-md-11 mt-5 ">
          <h4 className="titulo naranja ">Nuestros</h4>
          <h6 className="subtitulo negro ">TORNEOS</h6>
        </hgroup>
      </section>

        <section className="row justify-content-around   " style={{ backgroundColor: "#fff" }}>
          <div className="col-11 col-md-5">
            <p className="txt_somos  ">Naguará cuenta con un staff multidisciplinario y capacitado para realizar todo tipo de torneos con estándares de calidad profesionales, similares a las grandes ligas competitivas. Realiza constantemente en sus canales y los de sus creadores de contenido competencias destinadas a todo tipo de participantes: influencers, jugadores profesionales y amateurs.
              <br /><br />
              El equipo se ocupa de la realización integral de los mismos, garantizando tanto la organización e inscripciones como los streams y la búsqueda de talentos para casteo y difusión.
            </p>
            <div className="justify-content-center text-center">
              <img src="imgs/torneos.jpg" />
            </div>

          </div>
          <div className="col-12 col-md-5 p-0">
            <img src="imgs/torneos_img.png" width={"100%"} />
          </div>
          <img src="imgs/blanco_abajo.png" className="p-0" />
        </section>
</>
}
     

      <Sponsors />

      <footer className="row justify-content-center p-4" id="CONTACTO">
        <div className="col-10 misc">
          <h3>CONTACTO</h3>
        </div>
        <div className="col-12 col-md-7">
          <a href="mailto:contacto@trihaus.es" className="link_mail"> contacto@trihaus.es</a>
          <p></p>
        </div>
        <div className="col-12 col-md-3">
          <div className="d-flex mod_soc_foot justify-content-end">
            <a className="naranja" href="https://youtube.com/naguaragg" target="_blank"><img src="imgs/sos_youtube.png" className="sos_head" /></a>
            <a className="naranja" href="https://facebook.com/naguaragg" target="_blank"><img src="imgs/sos_facebook.png" className="sos_head" /></a>
            <a className="naranja" href="https://instagram.com/naguara.gg" target="_blank"><img src="imgs/sos_instagram.png" className="sos_head" /></a>
            <a className="naranja " href="https://twitter.com/naguaragg" target="_blank"><img src="imgs/sos_twitter.png" className="sos_head" /></a>
            <a className="naranja" href="https://tiktok.com/@naguragg" target="_blank"><img src="imgs/sos_boyhaa.png" className="sos_head" /></a>
            <a className="naranja " href="https://booyah.live/naguaragg" target="_blank"><img src="imgs/sos_tiktok.png" className="sos_head" /></a>
          </div>
        </div>
      </footer>



    </div>
  );
}

export default App;
