


const ModCreador = (props) => {
    return <div className="creadorModNew">
            <img src={"imgs/"+props.creador.img} width={"73%"} />
            {1==3 &&
            <hgroup>
                <h1>{props.creador.nombre}</h1>
                <h4 className="naranja">{props.creador.pais}</h4>
            </hgroup>
            }
            <ul>
                {props.creador.redes.map((red, i) => {
                    return <li key={i} className="mx-2"><a href={red.link} target="_blank"><img src={"imgs/creador_"+red.tipo+".png"} className="sos_head"/></a></li>
                })}
                
            </ul>
    </div>
}
export default ModCreador;
/*<li className="mx-2"><a href="#"><img src="imgs/creador_youtube.png" className="sos_head" /></a></li>
                <li className="mx-2"><a href="#"><img src="imgs/creador_facebook.png" className="sos_head" /></a></li>
                <li className="mx-2"><a href="#"><img src="imgs/creador_instagram.png" className="sos_head" /></a></li>
                <li className="mx-2"><a href="#"><img src="imgs/creador_tiktok.png" className="sos_head" /></a></li>
                <li className="mx-2"><a href="#"><img src="imgs/creador_boyhaa.png" className="sos_head" /></a></li>*/