import { Fragment } from "react"

const Sponsors = () => {
    return <Fragment>
        <section className="row justify-content-center " id="SPONSORS">
         <div className="col-11 col-md-4">
          <hgroup className="col-12  mt-5">
                <h4 className="titulo naranja ">NUESTROS</h4>
                <h6 className="subtitulo blanco ">SPONSORS</h6>
          </hgroup>
        </div>
        <div className="col-12 col-md-6 mt-5"> </div>

        </section>

        <section className="row justify-content-center ">
  

                <div className="col-6 col-md-4 col-lg-2"><a href="http://www.trihaus.es/" className="sponsor " target="_blank"><img className="grow" src="imgs/logos/tri.png" width={"100%"} /></a></div>
                <div className="col-6 col-md-4 col-lg-2"><a href="https://www.razer.com/" className="sponsor " target="_blank"><img className="grow" src="imgs/logos/razer.png" width={"100%"} /></a></div>
                <div className="col-6 col-md-4 col-lg-2"><a href="https://www.tiktok.com/es" className="sponsor " target="_blank"><img className="grow" src="imgs/logos/tiktok.png" width={"100%"} /></a></div>

       
        </section>
    </Fragment>
   
}

export default Sponsors